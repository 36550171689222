import React, { Component } from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Home extends Component {


    generateSection = () => {
        return (
            <section className='home-section'>
                <h1 className='home-heading'>Let's Make Some Masks.</h1>
                <Button className='home-button' variant="primary"><Link to="/masks" style={{ color: '#FFF' }}>Make Masks!</Link></Button>
                <p className='home-text'>A simple website explaining the current state of COVID-19, how to make masks, and the location + reliability of local hospitals to donate to.</p>
                <p className="footer">Developed by Karthik V., Content + Data by Tejesh A. and Aiden S., Graphics by Alexei Y.</p>
            </section>
        )
    }

    render() {
        return (
            <div>
                {this.generateSection()}
            </div>
        
        )
    }

}

export default Home;