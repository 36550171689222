import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './styles/styles.css';

import Header from './components/header';
import Home from './components/home';
import maskInfo from './components/maskInfo';
import HospitalInfo from './components/hospitalInfo';


const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <div className="container-fluid">
          <Switch>
            <Route path="/masks" component={maskInfo} />
            <Route path="/hospitals" component={HospitalInfo} />
            <Route path="/" component={Home} exact />
            <Route render={() => <h3>404 Page Not Found</h3>} />
          </Switch>
        </div>
      </BrowserRouter>
    </>
  )
}

export default App
