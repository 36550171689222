import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Header = () => {

    return (
        <header>
            <div className="d-flex flex-column flex-md-row align-item-center p-3 px-md-4 mb-3 border-bottom">
                <h5 className="my-0 mr-md-auto font-weight-normal">Help Make a Mask!</h5>
                <nav className="my-2 my-md-0 mr-md-3">
                    <NavLink
                        to="/"
                        className="p-2 text-dark"
                        //activeStyle={{ fontWeight: 'bold' }}
                        // activeClassName="selected"
                        exact
                    >Home</NavLink>

                    <NavLink
                        to="/masks"
                        className="p-2 text-dark"
                    //activeStyle={{ fontWeight: 'bold' }}
                    //activeClassName="selected"
                    >Masks</NavLink>

                    <NavLink
                        to="/hospitals"
                        className="p-2 text-dark"
                    // activeStyle={{ fontWeight: 'bold' }}
                    //activeClassName="selected"
                    >Hospitals</NavLink>
                </nav>
            </div>
        </header>
    )
}

export default Header;