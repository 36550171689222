import React, { Component } from "react";
import { Map, TileLayer, Popup, Marker } from "react-leaflet";
import { Spinner } from 'react-bootstrap';
import JSON from '../data/hospital_data.json';

class HospitalInfo extends Component {

    state = {
        data: JSON.data,
        lat: 47,
        lng: -150,
        loading: true,
    }

    changeLoadingState = () => {
        this.setState({
            loading: !this.state.loading,
        })
    }

    componentDidMount() {
        //      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        })
        
        this.changeLoadingState();
    }

    displayMap = (data) => {
        return (
            <>
                <Map
                    center={[this.state.lat, this.state.lng]}
                    zoom={12}
                    maxZoom={12}
                    minZoom={8}
                    attributionControl={true}
                    zoomControl={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}
                >
                    <TileLayer
                        url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    />
                    <Marker position={[this.state.lat, this.state.lng]}>
                        <Popup>
                            Your home!
                         </Popup>
                    </Marker>

                    {data}
                </Map>
            </>
        )
    }


    render() {
        const data = this.state.data.map(function (item, i) {
            return (
                <Marker key={i} position={[parseFloat(item.longitude), parseFloat(item.latitude)]}>
                    <Popup>
                        <h5> {item["Facility Name"]} </h5>
                        <li>Safety Comparsion: {item["Safety of care national comparison"]}</li>
                        <li>Patient Experience: {item["Patient experience national comparison"]}</li>
                        <li>Overall Rating (1-5): <strong>{item["Hospital overall rating"]}</strong></li>

                    </Popup>
                </Marker>
            )
        })

        return (
            <>
                {this.state.loading ? console.log("loading") : this.displayMap(data)}
            </>
        );
    }
}

export default HospitalInfo;
