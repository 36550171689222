import React from 'react';
import { Card, Button, ListGroup } from 'react-bootstrap';
const maskInfo = () => {




    return (
        <div className="mask-section">
            <h1 className="mask-title">Homemade Mask Tutorial</h1>

            <p className="mask-text">With the threat of COVID-19 prominent in our necessary day to day interactions, the importance of practicing good safety habits cannot be understated. In this
            tutorial, we shall be showing you how to create a low cost and effective mask which is both safe and comfortable.</p>

            <h4>Materials</h4>
            <ListGroup className="mask-materials">
                <ListGroup.Item>Sewing Machine</ListGroup.Item>
                <ListGroup.Item>An old shirt</ListGroup.Item>
                <ListGroup.Item>12” of elastic (rubber bands, hair ties, etc.)</ListGroup.Item>
                <ListGroup.Item>If no elastic, you can use string instead</ListGroup.Item>
                <ListGroup.Item>Scissors</ListGroup.Item>
            </ListGroup>


            <Card className="mask-card">
                <Card.Body>
                    <Card.Title> <h4>Step 1: Cut out 2 6”x10” rectangles from the shirt.</h4    ></Card.Title>
                    {/* <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                     </Card.Text> */}
                    <Card.Img variant="top" className="mask-img" src="https://cdn.discordapp.com/attachments/693246858264444989/703740498317279329/mask_Shirt.png" />
                </Card.Body>
            </Card>

            <Card className="mask-card">
                <Card.Body>
                    <Card.Title> <h4>Step 2: Stack the pieces of fabric on top of each other.</h4></Card.Title>
                </Card.Body>
            </Card>

            <Card className="mask-card">
                <Card.Body>
                    <Card.Title> <h4>Step 3: Fold the longer ends of the fabric over ¼” and sew them down. Then, fold the shorter ends of the fabric over ½” and sew that down, leaving room for elastic to run through the hole.</h4    ></Card.Title>
                    {/* <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                     </Card.Text> */}
                    <Card.Img variant="top" className="mask-img" src="https://cdn.discordapp.com/attachments/693246858264444989/703748916977598484/mask_with_BG.png" />
                </Card.Body>
            </Card>

            <Card className="mask-card">
                <Card.Body>
                    <Card.Title> <h4>Step 4: Run the elastic or string through the wider hem and tie the elastic to make a loop. These become the ear loops.</h4    ></Card.Title>
                    {/* <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                     </Card.Text> */}
                    <Card.Img variant="top" className="mask-img" src="https://cdn.discordapp.com/attachments/693246858264444989/703752874273800262/MASK_STRING.png" />
                </Card.Body>
            </Card>


            <h4 className="mask-card">Adjust the mask, and you're good to go!</h4>
        </div>
    )
}

export default maskInfo;